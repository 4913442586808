// @ts-nocheck
import React, { useState } from 'react'
import { Comment } from '../interfaces/comment.interface'
import { Link } from 'react-router-dom'
import { dateFormat } from '../utility'

interface Comments {
    comments:[]
}

const Comments: React.FC<Comments> = ({comments}) => {
    const [visible, setVisible] = useState(3)
    const showMore = () => {
        setVisible((prev) => prev + 3)
    }
  return (
    <>
        <div className="reviews-wrap panel vstack gap-4 mt-4">
            <div className="reviews-lisiting panel row child-cols-12 sep-x gy-9">
                {comments.slice(0, visible).map((e:Comment, index) => (
                    <div className="review-item" key={index}>
                        <div className="vstack items-start gap-2">
                            <div className="vstack gap-2 flex-1">

                                <p className="text-dark dark:text-white text-opacity-70">{e.comment}</p>
                            </div>
                            <div className="hstack items-center gap-2 h6 ft-secondary text-dark dark:text-white text-opacity-70">
                                <div className="w-40px">
                                    <figure className="featured-image m-0 ratio ratio-1x1 uc-transition-toggle overflow-hidden bg-gray-25 dark:bg-gray-800">
                                        <img className="media-cover image uc-transition-scale-up uc-transition-opaque" src="./../assets/images/common/img-fallback.png" data-src="../assets/images/common/users/img-01.png" alt="Reviewer name" data-uc-img="loading: lazy" />
                                    </figure>
                                </div>
                                <div className="hstack justify-between items-center gap-2 flex-1 fs-7 fw-normal">
                                    <span>By <Link className="text-none text-primary" to="#!">{e.user}</Link></span>
                                    <span>On {dateFormat(e.createdAt)}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            {comments.length > 3 && <div className="reviews-loadmore" >
                <Link to="#!" onClick={showMore} className="btn btn-sm btn-outline-gray-100 w-100 text-dark dark:text-white dark:border-gray-700 dark:hover:bg-gray-700">Load more comments</Link>
            </div>}
        </div>
    </>
  )
}

export default Comments
