import {configureStore} from "@reduxjs/toolkit"
import contentReducer from './../slices/content.slice'
import commentReducer from './../slices/comment.slice'
import subscribeReducer from './../slices/subscribe.slice'

export const store = configureStore({
    reducer: {
        contents: contentReducer,
        comments:commentReducer,
        subscribers:subscribeReducer
    }
})

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;