import { Link } from "react-router-dom"
import { useAppSelector } from "../hooks/dispatch.hook"
import slugify from "slugify"
import { agoTime } from "../utility"

interface Props {
    title:string,
    index:boolean
}

const NewsTile: React.FC <Props> = ({title, index}) => {
    const {contents} = useAppSelector(state => state.contents)
    const data = contents.filter((item) => item.category === title)
    const dataOne = data.slice(0,1)
    const dataTwo = data.slice(1, 5)
  return (
    <>
        <div className="section panel overflow-hidden">
            <div className={index===true ? "section-outer panel" : "section-outer panel py-4 lg:py-8"}>
                <div className="container max-w-xl">
                    <div className="section-inner">
                        <div className="block-layout grid-layout vstack gap-2 panel overflow-hidden">
                            <div className="block-header panel">
                                <h2 className="h5 xl:h4 m-0 text-black dark:text-white">
                                    <Link className="hstack d-inline-flex gap-0 text-none hover:text-primary duration-150" to={"/category/"+slugify(title, {lower:true})}>
                                        <span>{title}</span>
                                        <i className="icon-1 fw-bold unicon-chevron-right -translate-y-px"></i>
                                    </Link>
                                </h2>
                            </div>
                            <div className="block-content">
                                <div className="panel row child-cols-12 md:child-cols g-1 col-match" data-uc-grid>
                                    <div className="col-12 md:col-6 order-0 md:order-1">
                                        {dataOne.map((item, index) => (
                                            <div key={index}>
                                            <article className="post type-post panel uc-transition-toggle vstack gap-2 lg:gap-3 h-100 overflow-hidden rounded uc-dark">
                                                <div className="post-media panel overflow-hidden h-100">
                                                    <div className="featured-image bg-gray-25 dark:bg-gray-800 h-100 d-none md:d-block">
                                                        <canvas className="h-100 w-100"></canvas>
                                                        <img className="media-cover image uc-transition-scale-up uc-transition-opaque" src={item.image} data-src={item.image} alt={item.slug} data-uc-img="loading: lazy" />
                                                    </div>
                                                    <div className="featured-image bg-gray-25 dark:bg-gray-800 ratio ratio-16x9 d-block md:d-none">
                                                        <img className="media-cover image uc-transition-scale-up uc-transition-opaque" src={item.image} data-src={item.image} alt={item.slug} data-uc-img="loading: lazy" />
                                                    </div>
                                                </div>
                                                <div className="position-cover bg-gradient-to-t from-black to-transparent opacity-90"></div>
                                                <div className="post-header panel vstack justify-end items-start gap-1 p-2 sm:p-4 position-cover text-white">
                                                    <div className="post-date hstack gap-narrow fs-7 fw-medium text-uppercase text-gray-900 dark:text-white text-opacity-60 d-none md:d-flex">
                                                        <span>{agoTime(item.createdAt)}</span>
                                                    </div>
                                                    <h3 className="post-title h5 lg:h4 m-0 max-w-600px text-white text-truncate-2">
                                                        <Link className="text-none text-white" to={"/post/"+item.slug}>{item.title}</Link>
                                                    </h3>
                                                    <div>
                                                        <div className="post-meta panel hstack justify-between fs-7 fw-medium text-white text-opacity-60 mt-1">
                                                            <div className="meta">
                                                                <div className="hstack gap-2">
                                                                    <div>
                                                                        <div className="post-author hstack gap-1">
                                                                            {/* <Link to={"#"} data-uc-tooltip="Sarah Eddrissi"><img src="../assets/images/avatars/03.png" alt={item.author} className="w-24px h-24px rounded-circle" /></Link> */}
                                                                            <Link to="#" className="text-black dark:text-white text-none fw-bold">{item.author}</Link>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <Link to="#" className="post-comments text-none hstack gap-narrow">
                                                                            <i className="icon-narrow unicon-chat"></i>
                                                                            <span>0</span>
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="actions">
                                                                <div className="hstack gap-1"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </article>
                                        </div>
                                        ))}
                                        
                                    </div>
                                    <div className="order-1 md:order-0">
                                        <div className="row child-cols-12 g-1">
                                            {dataTwo.map((item, index) => (
                                                <div key={index}>
                                                    <article className="post type-post panel uc-transition-toggle bg-white dark:bg-gray-900 p-2 rounded">
                                                        <div className="row child-cols g-2" data-uc-grid>
                                                            <div>
                                                                <div className="post-header panel vstack justify-between gap-1">
                                                                    <h3 className="post-title h6 lg:h5 m-0 text-truncate-2">
                                                                        <Link className="text-none hover:text-primary duration-150" to={"/post/"+item.slug}>{item.title}</Link>
                                                                    </h3>
                                                                    <div className="post-meta panel hstack justify-start gap-1 fs-7 ft-tertiary fw-medium text-uppercase text-gray-900 dark:text-white text-opacity-60 d-none md:d-flex z-1">
                                                                        <div>
                                                                            <div className="post-category hstack gap-narrow fw-semibold">
                                                                                <Link className="text-none text-primary dark:text-primary-400" to="#">{item.subcategory ? item.subcategory : item.category}</Link>
                                                                            </div>
                                                                        </div>
                                                                        <div className="sep d-none md:d-block">·</div>
                                                                        <div className="d-none md:d-block">
                                                                            <div className="post-date hstack gap-narrow">
                                                                                <span>{agoTime(item.createdAt)}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-auto">
                                                                <div className="post-media panel overflow-hidden max-w-128px min-w-72px lg:min-w-128px rounded-1">
                                                                    <div className="featured-image bg-gray-25 dark:bg-gray-800 ratio ratio-3x2">
                                                                        <img className="media-cover image uc-transition-scale-up uc-transition-opaque" src={item.image} data-src={item.image} alt={item.slug} data-uc-img="loading: lazy" />
                                                                    </div>
                                                                    <Link to={"/post/"+item.slug} className="position-cover"></Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </article>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default NewsTile
