import React from 'react'
import { Link } from 'react-router-dom'

function Footer() {
  return (
        <footer id="uc-footer" className="uc-footer panel uc-dark">
            <div className="footer-outer py-4 lg:py-6 bg-white dark:bg-gray-900 text-gray-900 dark:text-white text-opacity-50">
                <div className="container max-w-xl">
                    <div className="footer-inner vstack gap-6 xl:gap-8">
                        {/* <div className="uc-footer-top">
                            <div className="row child-cols-12 col-match g-4 lg:g-6" data-uc-grid>
                                <div className="md:col-4">
                                    <div className="widget vstack gap-2 lg:gap-3">
                                        <div className="widget-title text-center">
                                            <h5 className="fs-7 text-uppercase m-0">FT Matches</h5>
                                        </div>
                                        <div className="widget-content border rounded overflow-hidden">
                                            <div className="row child-cols-12 g-0 sep-x max-h-200px overflow-auto">
                                                <div>
                                                    <div className="panel lh-sm p-2 text-center hover:bg-gray-25 dark:hover:bg-gray-800 duration-150 uc-visible-toggle" tabIndex={0}>
                                                        <div className="hstack gap-1">
                                                            <div className="vstack gap-narrow">
                                                                <img src="../assets/images/demo-six/emblems/logo-07.svg" alt="Team E" className="w-32px h-32px m-auto" />
                                                                <span className="fs-5 fw-bold text-uppercase mt-1 text-gray-900 dark:text-white d-none md:d-block">Team E</span>
                                                            </div>
                                                            <div className="vstack justify-center gap-narrow">
                                                                <span className="fs-5 md:fs-4 fw-bold">2 - 6</span>
                                                                <span className="fs-7 text-gray-900 dark:text-white text-opacity-50">FT</span>
                                                            </div>
                                                            <div className="vstack gap-narrow">
                                                                <img src="../assets/images/demo-six/emblems/logo-10.svg" alt="Team J" className="w-32px h-32px m-auto" />
                                                                <span className="fs-5 fw-bold text-uppercase mt-1 text-gray-900 dark:text-white d-none md:d-block">Team J</span>
                                                            </div>
                                                        </div>
                                                        <a href="#" className="position-absolute top-50 start-50 translate-middle btn btn-2xs btn-primary text-white px-1 rounded-1 fs-7 z-1 uc-invisible-hover">
                                                            <span>View details</span>
                                                        </a>
                                                        <a href="#" className="position-cover"></a>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="panel lh-sm p-2 text-center hover:bg-gray-25 dark:hover:bg-gray-800 duration-150 uc-visible-toggle" tabIndex={0}>
                                                        <div className="hstack gap-1">
                                                            <div className="vstack gap-narrow">
                                                                <img src="../assets/images/demo-six/emblems/logo-08.svg" alt="Team H" className="w-32px h-32px m-auto" />
                                                                <span className="fs-5 fw-bold text-uppercase mt-1 text-gray-900 dark:text-white d-none md:d-block">Team H</span>
                                                            </div>
                                                            <div className="vstack justify-center gap-narrow">
                                                                <span className="fs-5 md:fs-4 fw-bold">1 - 0</span>
                                                                <span className="fs-7 text-gray-900 dark:text-white text-opacity-50">FT</span>
                                                            </div>
                                                            <div className="vstack gap-narrow">
                                                                <img src="../assets/images/demo-six/emblems/logo-05.svg" alt="Team E" className="w-32px h-32px m-auto" />
                                                                <span className="fs-5 fw-bold text-uppercase mt-1 text-gray-900 dark:text-white d-none md:d-block">Team E</span>
                                                            </div>
                                                        </div>
                                                        <a href="#" className="position-absolute top-50 start-50 translate-middle btn btn-2xs btn-primary text-white px-1 rounded-1 fs-7 z-1 uc-invisible-hover">
                                                            <span>View details</span>
                                                        </a>
                                                        <a href="#" className="position-cover"></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="md:col-4">
                                    <div className="widget vstack gap-2 lg:gap-3">
                                        <div className="widget-title text-center">
                                            <h5 className="fs-7 text-uppercase m-0">Live now</h5>
                                        </div>
                                        <div className="widget-content border rounded overflow-hidden">
                                            <div className="row child-cols-12 g-0 sep-x max-h-200px overflow-auto">
                                                <div>
                                                    <div className="panel lh-sm p-2 text-center hover:bg-gray-25 dark:hover:bg-gray-800 duration-150 uc-visible-toggle" tabIndex={0}>
                                                        <div className="hstack gap-1">
                                                            <div className="vstack gap-narrow">
                                                                <img src="../assets/images/demo-six/emblems/logo-01.svg" alt="Team A" className="w-32px h-32px m-auto" />
                                                                <span className="fs-5 fw-bold text-uppercase mt-1 text-gray-900 dark:text-white d-none md:d-block">Team A</span>
                                                            </div>
                                                            <div className="vstack justify-center gap-narrow">
                                                                <span className="fs-5 md:fs-4 fw-bold text-red">3 - 2</span>
                                                                <span className="fs-7 text-gray-900 dark:text-white text-opacity-50">72<b className="fs-6" data-uc-animate="flash">’</b></span>
                                                            </div>
                                                            <div className="vstack gap-narrow">
                                                                <img src="../assets/images/demo-six/emblems/logo-02.svg" alt="Team B" className="w-32px h-32px m-auto" />
                                                                <span className="fs-5 fw-bold text-uppercase mt-1 text-gray-900 dark:text-white d-none md:d-block">Team B</span>
                                                            </div>
                                                        </div>
                                                        <a href="#" className="position-absolute top-50 start-50 translate-middle btn btn-2xs btn-red text-white px-1 rounded-1 fs-7 z-1 uc-invisible-hover">
                                                            <span>Watch</span>
                                                        </a>
                                                        <a href="#" className="position-cover"></a>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="panel lh-sm p-2 text-center hover:bg-gray-25 dark:hover:bg-gray-800 duration-150 uc-visible-toggle" tabIndex={0}>
                                                        <div className="hstack gap-1">
                                                            <div className="vstack gap-narrow">
                                                                <img src="../assets/images/demo-six/emblems/logo-03.svg" alt="Team C" className="w-32px h-32px m-auto" />
                                                                <span className="fs-5 fw-bold text-uppercase mt-1 text-gray-900 dark:text-white d-none md:d-block">Team C</span>
                                                            </div>
                                                            <div className="vstack justify-center gap-narrow">
                                                                <span className="fs-5 md:fs-4 fw-bold text-red">0 - 0</span>
                                                                <span className="fs-7 text-gray-900 dark:text-white text-opacity-50">24<b className="fs-6" data-uc-animate="flash">’</b></span>
                                                            </div>
                                                            <div className="vstack gap-narrow">
                                                                <img src="../assets/images/demo-six/emblems/logo-04.svg" alt="Team D" className="w-32px h-32px m-auto" />
                                                                <span className="fs-5 fw-bold text-uppercase mt-1 text-gray-900 dark:text-white d-none md:d-block">Team D</span>
                                                            </div>
                                                        </div>
                                                        <a href="#" className="position-absolute top-50 start-50 translate-middle btn btn-2xs btn-red text-white px-1 rounded-1 fs-7 z-1 uc-invisible-hover">
                                                            <span>Watch</span>
                                                        </a>
                                                        <a href="#" className="position-cover"></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="md:col-4">
                                    <div className="widget vstack gap-2 lg:gap-3">
                                        <div className="widget-title text-center">
                                            <h5 className="fs-7 text-uppercase m-0">Next Fixtures</h5>
                                        </div>
                                        <div className="widget-content border rounded overflow-hidden">
                                            <div className="row child-cols-12 g-0 sep-x max-h-200px overflow-auto">
                                                <div>
                                                    <div className="panel lh-sm p-2 text-center hover:bg-gray-25 dark:hover:bg-gray-800 duration-150 uc-visible-toggle" tabIndex={0}>
                                                        <div className="hstack gap-1">
                                                            <div className="vstack gap-narrow">
                                                                <img src="../assets/images/demo-six/emblems/logo-05.svg" alt="Team E" className="w-32px h-32px m-auto" />
                                                                <span className="fs-5 fw-bold text-uppercase mt-1 text-gray-900 dark:text-white d-none md:d-block">Team E</span>
                                                            </div>
                                                            <div className="vstack justify-center gap-narrow">
                                                                <span className="fs-5 md:fs-4 fw-bold">16:30</span>
                                                                <span className="fs-7 text-gray-900 dark:text-white text-opacity-50">12/04</span>
                                                            </div>
                                                            <div className="vstack gap-narrow">
                                                                <img src="../assets/images/demo-six/emblems/logo-06.svg" alt="Team F" className="w-32px h-32px m-auto" />
                                                                <span className="fs-5 fw-bold text-uppercase mt-1 text-gray-900 dark:text-white d-none md:d-block">Team F</span>
                                                            </div>
                                                        </div>
                                                        <a href="#" className="position-absolute top-50 start-50 translate-middle btn btn-2xs btn-primary text-white px-1 rounded-1 fs-7 z-1 uc-invisible-hover">
                                                            <span>View details</span>
                                                        </a>
                                                        <a href="#" className="position-cover"></a>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="panel lh-sm p-2 text-center hover:bg-gray-25 dark:hover:bg-gray-800 duration-150 uc-visible-toggle" tabIndex={0}>
                                                        <div className="hstack gap-1">
                                                            <div className="vstack gap-narrow">
                                                                <img src="../assets/images/demo-six/emblems/logo-07.svg" alt="Team G" className="w-32px h-32px m-auto" />
                                                                <span className="fs-5 fw-bold text-uppercase mt-1 text-gray-900 dark:text-white d-none md:d-block">Team G</span>
                                                            </div>
                                                            <div className="vstack justify-center gap-narrow">
                                                                <span className="fs-5 md:fs-4 fw-bold">20:00</span>
                                                                <span className="fs-7 text-gray-900 dark:text-white text-opacity-50">12/04</span>
                                                            </div>
                                                            <div className="vstack gap-narrow">
                                                                <img src="../assets/images/demo-six/emblems/logo-08.svg" alt="Team H" className="w-32px h-32px m-auto" />
                                                                <span className="fs-5 fw-bold text-uppercase mt-1 text-gray-900 dark:text-white d-none md:d-block">Team H</span>
                                                            </div>
                                                        </div>
                                                        <a href="#" className="position-absolute top-50 start-50 translate-middle btn btn-2xs btn-primary text-white px-1 rounded-1 fs-7 z-1 uc-invisible-hover">
                                                            <span>View details</span>
                                                        </a>
                                                        <a href="#" className="position-cover"></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <div className="uc-footer-bottom panel vstack gap-4 justify-center lg:fs-5">
                            <nav className="footer-nav">
                                <ul className="nav-x gap-2 lg:gap-4 justify-center text-center text-uppercase fw-medium">
                                    <li><Link className="hover:text-gray-900 dark:hover:text-white duration-150" to="/category/football">Football</Link></li>
                                    <li><Link className="hover:text-gray-900 dark:hover:text-white duration-150" to="/category/basketball">Basketball</Link></li>
                                    <li><Link className="hover:text-gray-900 dark:hover:text-white duration-150" to="/category/tennis">Tennis</Link></li>
                                    <li><Link className="hover:text-gray-900 dark:hover:text-white duration-150" to="/category/more-sports">More Sports</Link></li>
                                </ul>
                            </nav>
                            <div className="footer-social hstack justify-center gap-2 lg:gap-3">
                                <ul className="nav-x gap-2">
                                    {/* <li>
                                        <a className="hover:text-gray-900 dark:hover:text-white duration-150" href="#ln"><i className="icon icon-2 unicon-logo-linkedin"></i></a>
                                    </li>*/}
                                    <li>
                                        <Link className="hover:text-gray-900 dark:hover:text-white duration-150" to="https://www.facebook.com/profile.php?id=61565582300556"><i className="icon icon-2 unicon-logo-facebook"></i></Link>
                                    </li> 
                                    <li>
                                        <Link className="hover:text-gray-900 dark:hover:text-white duration-150" to="https://x.com/playaaxsports?t=o1_z8RdOvk5F4dkJrvJIuA&s=09"><i className="icon icon-2 unicon-logo-x-filled"></i></Link>
                                    </li>
                                    <li>
                                        <Link className="hover:text-gray-900 dark:hover:text-white duration-150" to="https://www.instagram.com/playaaxsports?utm_source=qr&igsh=a2ZlZ2U3ZmpxdXo1"><i className="icon icon-2 unicon-logo-instagram"></i></Link>
                                    </li>
                                    {/* <li>
                                        <a className="hover:text-gray-900 dark:hover:text-white duration-150" href="#yt"><i className="icon icon-2 unicon-logo-youtube"></i></a>
                                    </li> */}
                                </ul>
                                <div className="vr"></div>
                                <div className="d-inline-block">
                                    <a href="#" className="hstack gap-1 text-none fw-medium">
                                        <i className="icon icon-1 unicon-earth-filled"></i>
                                        <span>English</span>
                                        <span data-uc-drop-parent-icon=""></span>
                                    </a>
                                    <div className="p-2 bg-white dark:bg-gray-800 shadow-xs rounded w-150px" data-uc-drop="mode: click; boundary: !.uc-footer-bottom; animation: uc-animation-slide-top-small; duration: 150;">
                                        <ul className="nav-y gap-1 fw-medium items-end">
                                            <li><a href="#en">English</a></li>
                                            <li><a href="#ar">العربية</a></li>
                                            <li><a href="#ch">中文</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="footer-copyright vstack sm:hstack justify-center items-center gap-1 lg:gap-2">
                                <p>Playaax © 2025, All rights reserved.</p>
                                <ul className="nav-x gap-2 fw-medium">
                                    <li><Link className="uc-link text-underline hover:text-gray-900 dark:hover:text-white duration-150" to="/privacy-policy">Privacy Policy</Link></li>
                                    <li>contact:<Link className="uc-link text-underline hover:text-gray-900 dark:hover:text-white duration-150" to="#">playaaxsports@gmail.com</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
  )
}

export default Footer
