// @ts-nocheck
import React, { useState } from 'react'
import { addNewSubscriber } from '../slices/subscribe.slice'
import { useAppDispatch, useAppSelector } from '../hooks/dispatch.hook'

function Subscribe() {
    const dispatch = useAppDispatch()
    const [formData, setFormData] = useState({email:''})
    const {isLoading, msg} = useAppSelector(state => state.subscribers)

    const handleChange = (e:any) => {
        setFormData(state => ({
            ...state,
            [e.target.name]: e.target.value
        }))
    }

    const handleSubscribe = async (e:any) => {
        e.preventDefault()
        await dispatch(addNewSubscriber(formData))
        setFormData({email:''})

    }
  return (
    <>
        <form className="vstack gap-1" onSubmit={handleSubscribe}>
            <input className="form-control fs-6 fw-medium h-48px w-full bg-white dark:bg-gray-800 dark:bg-gray-800 dark:border-white dark:border-opacity-15 dark:border-opacity-15" name="email" type="email" placeholder="Your email" onChange={handleChange} required />
            <button className="btn btn-md btn-gray-900 dark:bg-white dark:text-gray-900 text-white" type="submit">{isLoading ? 'Sending...':'Subscribe'}</button>
        </form>
        <p style={{fontSize:'11px'}}>{msg}</p>
    </>
  )
}

export default Subscribe
