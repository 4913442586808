import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'


interface ShareProps {
    title:string,
    url:string,
    description:string,
    image:string
}

const Share: React.FC<ShareProps> = ({title, url, image, description}) => {
    const encodeUrl = encodeURIComponent(url)
    const encodeTitle = encodeURIComponent(title)
    const encodeDescription = encodeURIComponent(description)
    const SocialLinks = {
        twitter:`https://twitter.com/intent/tweet?url=${encodeUrl}&text=${encodeTitle}`,
        facebook:`https://www.facebook.com/sharer/sharer.php?u=${encodeUrl}`,
        whatsapp:`https://api.whatsapp.com/send?text=${encodeTitle} - ${encodeUrl}`
    }
  return (
    <>
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={image} />
            <meta property="og:url" content={url} />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content={image} />
        </Helmet>
        <ul className="post-share-icons nav-x gap-narrow">
            <li className="me-1"><span className="text-black dark:text-white">Share:</span></li>
            <li>
                <Link className="btn btn-md btn-outline-gray-100 p-0 w-32px lg:w-40px h-32px lg:h-40px text-dark dark:text-white dark:border-gray-600 hover:bg-primary hover:border-primary hover:text-white rounded-circle" to={SocialLinks.facebook} target="_blank"><i className="unicon-logo-facebook icon-1"></i></Link>
            </li>
            <li>
                <Link className="btn btn-md btn-outline-gray-100 p-0 w-32px lg:w-40px h-32px lg:h-40px text-dark dark:text-white dark:border-gray-600 hover:bg-primary hover:border-primary hover:text-white rounded-circle" to={SocialLinks.twitter} target="_blank"><i className="unicon-logo-x-filled icon-1"></i></Link>
            </li>
            <li>
                <Link className="btn btn-md btn-outline-gray-100 p-0 w-32px lg:w-40px h-32px lg:h-40px text-dark dark:text-white dark:border-gray-600 hover:bg-primary hover:border-primary hover:text-white rounded-circle" to={SocialLinks.whatsapp} target="_blank"><img src="./../assets/images/demo-six/whatsapp-logo.svg" className="unicon-whatsapp icon-1"></img></Link>
            </li>
            <li>
                <Link className="btn btn-md btn-outline-gray-100 p-0 w-32px lg:w-40px h-32px lg:h-40px text-dark dark:text-white dark:border-gray-600 hover:bg-primary hover:border-primary hover:text-white rounded-circle" to="#" target="_blank"><i className="unicon-email icon-1"></i></Link>
            </li>
        </ul>
    </>
  )
}

export default Share
