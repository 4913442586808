 import axios from "axios";
import { Comment } from "../interfaces/comment.interface";
import { Subscribe } from "../interfaces/subscriber.interface";

 //const baseUrl = "http://localhost:3000/api/playaax/1.0"
 const baseUrl = "https://yivhkuyrzh.execute-api.us-east-1.amazonaws.com/api/playaax/1.0"

 const axiosInstance = axios.create({
    baseURL:baseUrl
 })

// endpoint routes...
export const addSubscriber = (data:Subscribe) => axiosInstance.post('/subscribe/new', data)
export const addComment = (data:Comment) => axiosInstance.post('/comment/add-comment', data)
export const getComments = () => axiosInstance.get('/comment/pull?status=true&sortby=createdAt&orderby=desc')
export const getContent = (slug:string) => axiosInstance.get('/content/by-identity?slug='+slug)
export const getContents = () => axiosInstance.get('/content/pull?del_flag=0&status=true&sortby=createdAt&orderby=desc')