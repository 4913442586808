import { createSlice, createAsyncThunk  } from '@reduxjs/toolkit'
import { Comment } from '../interfaces/comment.interface'
import { addComment, getComments } from '../app/axios'

export interface InitialState {
    isLoading:boolean,
    commente:Comment,
    comments:Comment[],
    error:string,
    status:boolean
}

const initialState:InitialState = {
    isLoading:false,
    commente:{
        user:'',
        postId:'',
        email:'',
        comment:''
    },
    comments:[],
    error:'',
    status:false
}

export const addNewComment = createAsyncThunk('comment/addNewComment', async (data:Comment) => {
    const response = await addComment(data)
    return response.data
})

export const fetchComments = createAsyncThunk('comment/fetchComments', async () => {
    const response = await getComments()
    return response.data
})

const commentSlice = createSlice({
    name:'comments',
    initialState,
    reducers:{},
    extraReducers: (builder) => {
        builder
            .addCase(addNewComment.pending, (state) => {
                state.isLoading = true
            })
            .addCase(addNewComment.fulfilled, (state, action) => {
                state.isLoading = false
                state.comments.push(action.payload.response)
                state.status = true
            })
            .addCase(addNewComment.rejected, (state) => {
                state.isLoading = false
                state.status = false
                state.error = 'Error sending comment'
            })
            .addCase(fetchComments.pending, (state) => {
                state.isLoading = true
            })
            .addCase(fetchComments.fulfilled, (state, action) => {
                state.isLoading = false
                state.comments = action.payload.response
            })
            .addCase(fetchComments.rejected, (state) => {
                state.isLoading = false
                state.error = 'Error fetching data'
            })
    }
})

export default commentSlice.reducer