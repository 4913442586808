// @ts-nocheck
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../hooks/dispatch.hook'
import Share from '../components/share'
import { dateFormat } from '../utility'
import slugify from 'slugify'
import { getContent } from '../app/axios'
import { addNewComment, fetchComments } from '../slices/comment.slice'
import Comments from '../components/comments'
import Subscribe from '../components/subscribe'

function Content() {
    const {slug} = useParams()
    const [data, setData] = useState({
        title:'',
        slug:'',
        format:'',
        category:'',
        subcategory:'',
        content:'',
        tags:[],
        editorial:null,
        featured:null,
        main:null,
        image:""
    })
    const dispatch = useAppDispatch()
    const {contents} = useAppSelector(state => state.contents)
    const {isLoading, comments, status} = useAppSelector(state => state.comments)
    const related = contents.filter((item) => item.category === data.category && item.slug !== slug).slice(0, 3)
    useEffect(() => {
        const fetchPost = async () => {
            let isMounted = true
            const controller = new AbortController()
            try {
                const response = await getContent(slug)
                if (isMounted){
                    dispatch(fetchComments())
                    setData(response.data.response)}
                return () => {
                    isMounted = false
                    controller.abort()
                }
            }catch(err){
                console.error(err)
            }
        }
        fetchPost()
    },[slug])
    const _comments = comments.filter(item => item.slug === slug)
    const [comment, setComment] = useState({
        user:'',
        slug:slug,
        email:'',
        comment:''
    })

    const handleChange = (e:any) => {
        setComment(state => ({
            ...state,
            [e.target.name]: e.target.value
        }))
    }
    const sendComment = async (e:any) => {
        e.preventDefault()
        try{
            const response = await dispatch(addNewComment(comment))
            //if (response.status){
                setComment({
                    user:'',
                    email:'',
                    comment:''
                })
            //}
        }catch(err){
            console.log(err)
        }
    }

  return (
    <>
            <div className="breadcrumbs panel z-1 py-2 bg-gray-25 dark:bg-gray-100 dark:bg-opacity-5 dark:text-white">
                <div className="container max-w-xl">
                    <ul className="breadcrumb nav-x justify-center gap-1 fs-7 sm:fs-6 m-0">
                        <li><Link to="/">Home</Link></li>
                        <li><i className="unicon-chevron-right opacity-50"></i></li>
                        <li><a href="#!">Post</a></li>
                        <li><i className="unicon-chevron-right opacity-50"></i></li>
                        <li><span className="opacity-50">{data?.title}</span></li>
                    </ul>
                </div>
            </div> 
            <div className="container max-w-xl">
                <div className="section-inner">
                    <div className="content-wrap row child-cols-12 g-4">
                        <div className="md:col-9">
                            <article className="post type-post single-post py-4 lg:py-6 xl:py-9">
                    
                                <div className="container max-w-xl">
                                    <div className="post-header">
                                        <div className="panel vstack gap-4 md:gap-6 xl:gap-8 text-center">
                                            <div className="panel vstack items-center max-w-400px sm:max-w-500px xl:max-w-md mx-auto gap-2 md:gap-3">
                                                <h1 className="h4 sm:h2 lg:h1 xl:display-6">{data?.title}</h1>
                                                
                                            </div>
                                            <figure className="featured-image m-0">
                                                <figure className="featured-image m-0 ratio ratio-2x1 rounded uc-transition-toggle overflow-hidden bg-gray-25 dark:bg-gray-800">
                                                    <img className="media-cover image uc-transition-scale-up uc-transition-opaque" src="../assets/images/common/img-fallback.png" data-src={data?.image} alt="The Rise of Gourmet Street Food: Trends and Top Picks" data-uc-img="loading: lazy" />
                                                </figure>
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                                <div className="panel mt-4 lg:mt-6 xl:mt-9">
                                    <div className="container max-w-lg">
                                        <div className="post-content panel fs-6 md:fs-5" data-uc-lightbox="animation: scale">
                                            <span dangerouslySetInnerHTML={{__html:data?.content}}></span>
                                        </div>
                                        <div className="post-footer panel vstack sm:hstack gap-3 justify-between justifybetween border-top py-4 mt-4 xl:py-5 xl:mt-5">
                                            <ul className="nav-x gap-narrow text-primary">
                                                <li><span className="text-black dark:text-white me-narrow">Tags:</span></li>
                                                {data?.tags.length && data.tags.map((tag, index) => (
                                                    <li key={index}>
                                                        <Link to={"/tag/"+slugify(tag, {lower:true})} className="uc-link gap-0 dark:text-white">{tag} <span className="text-black dark:text-white">, </span></Link>
                                                    </li>
                                                ))}
                                            </ul>
                                            <Share url={`https://playaax.com/post/`+slug} title={data.title} description={data.content} image={data.image} />
                                        </div>
                                        {/* <div className="post-author panel py-4 px-3 sm:p-3 xl:p-4 bg-gray-25 dark:bg-opacity-10 rounded lg:rounded-2">
                                            <div className="row g-4 items-center">
                                                <div className="col-12 sm:col-5 xl:col-3">
                                                    <figure className="featured-image m-0 ratio ratio-1x1 rounded uc-transition-toggle overflow-hidden bg-gray-25 dark:bg-gray-800">
                                                        <img className="media-cover image uc-transition-scale-up uc-transition-opaque" src="../assets/images/common/img-fallback.png" data-src="../assets/images/demo-six/posts/post-author.jpg" alt="Amir Nisi" data-uc-img="loading: lazy" />
                                                    </figure>
                                                </div>
                                                <div className="col">
                                                    <div className="panel vstack items-start gap-2 md:gap-3">
                                                        <h4 className="h5 lg:h4 m-0">Amir Nisi</h4>
                                                        <p className="fs-6 lg:fs-5">Creative and experienced content writer with 6+ years of experience lazy to create unique content strategy for News5 to turn website visitors into customers.</p>
                                                        <ul className="nav-x gap-1 text-gray-400 dark:text-white">
                                                            <li>
                                                                <a href="#medium"><i className="icon-2 unicon-logo-medium"></i></a>
                                                            </li>
                                                            <li>
                                                                <a href="#twitter"><i className="icon-2 unicon-logo-x-filled"></i></a>
                                                            </li>
                                                            <li>
                                                                <a href="#instagram"><i className="icon-2 unicon-logo-linkedin"></i></a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                        {/* <div className="post-navigation panel vstack sm:hstack justify-between gap-2 mt-8 xl:mt-9">
                                            <div className="new-post panel hstack w-100 sm:w-1/2">
                                                <div className="panel hstack justify-center w-100px h-100px">
                                                    <figure className="featured-image m-0 ratio ratio-1x1 rounded uc-transition-toggle overflow-hidden bg-gray-25 dark:bg-gray-800">
                                                        <img className="media-cover image uc-transition-scale-up uc-transition-opaque" src="../assets/images/common/img-fallback.png" data-src="../assets/images/demo-six/posts/img-02.jpg" alt="Tech Innovations Reshaping the Retail Landscape: AI Payments" data-uc-img="loading: lazy" />
                                                        <a href="blog-details.html" className="position-cover" data-caption="Tech Innovations Reshaping the Retail Landscape: AI Payments"></a>
                                                    </figure>
                                                </div>
                                                <div className="panel vstack justify-center px-2 gap-1 w-1/3">
                                                    <span className="fs-7 opacity-60">Prev Article</span>
                                                    <h6 className="h6 lg:h5 m-0">Tech Innovations Reshaping the Retail Landscape: AI Payments</h6>
                                                </div>
                                                <a href="blog-details.html" className="position-cover"></a>
                                            </div>
                                            <div className="new-post panel hstack w-100 sm:w-1/2">
                                                <div className="panel vstack justify-center px-2 gap-1 w-1/3 text-end">
                                                    <span className="fs-7 opacity-60">Next Article</span>
                                                    <h6 className="h6 lg:h5 m-0">The Rise of AI-Powered Personal Assistants: How They Manage</h6>
                                                </div>
                                                <div className="panel hstack justify-center w-100px h-100px">
                                                    <figure className="featured-image m-0 ratio ratio-1x1 rounded uc-transition-toggle overflow-hidden bg-gray-25 dark:bg-gray-800">
                                                        <img className="media-cover image uc-transition-scale-up uc-transition-opaque" src="../assets/images/common/img-fallback.png" data-src="../assets/images/demo-six/posts/img-01.jpg" alt="The Rise of AI-Powered Personal Assistants: How They Manage" data-uc-img="loading: lazy" />
                                                        <a href="blog-details.html" className="position-cover" data-caption="The Rise of AI-Powered Personal Assistants: How They Manage"></a>
                                                    </figure>
                                                </div>
                                                <a href="blog-details.html" className="position-cover"></a>
                                            </div>
                                        </div> */}
                                        <div className="post-related panel border-top pt-2 mt-6 xl:mt-6 mb-6">
                                            <h4 className="h5 xl:h4 mb-5 xl:mb-6">Related to this topic:</h4>
                                            <div className="row child-cols-6 md:child-cols-4 gx-2 gy-4 sm:gx-3 sm:gy-6">
                                                {related.map((item, index) => (
                                                    <div key={index}>
                                                        <article className="post type-post panel vstack gap-2">
                                                            <figure className="featured-image m-0 ratio ratio-4x3 rounded uc-transition-toggle overflow-hidden bg-gray-25 dark:bg-gray-800">
                                                                <img className="media-cover image uc-transition-scale-up uc-transition-opaque" src="../assets/images/common/img-fallback.png" data-src={item.image} alt="The Art of Baking: From Classic Bread to Artisan Pastries" data-uc-img="loading: lazy" />
                                                                <Link to={"/post/"+item.slug} className="position-cover" data-caption="The Art of Baking: From Classic Bread to Artisan Pastries"></Link>
                                                            </figure>
                                                            <div className="post-header panel vstack gap-1">
                                                                <h5 className="h6 md:h5 m-0">
                                                                    <Link className="text-none" to={'/post/'+item.slug}>{item.title}</Link>
                                                                </h5>
                                                                <div className="post-date hstack gap-narrow fs-7 opacity-60">
                                                                    <span>{dateFormat(item.createdAt)}</span>
                                                                </div>
                                                            </div>
                                                        </article>
                                                    </div>
                                                ))}
                                                
                                            </div>
                                        </div>
                                        <div>
                                            {_comments.length > 0 && <div className="panel vstack gap-1 lg:gap-2">
                                                <h4 className="h5 lg:h4 m-0">Comments ({_comments.length})</h4>
                                                <Comments comments={_comments}/>
                                            </div>}
                                        </div>
                                        {_comments.length < 1 && <a href="#!" className="btn h-56px w-100 mt-8 xl:mt-9 text-black dark:text-white bg-gray-25 dark:bg-opacity-10 hover:bg-gray-50 dark:hover:bg-gray-700">
                                            <span>Be the first to write a comment.</span>
                                        </a>}
                                        
                                        <form className="panel vstack gap-2 lg:gap-3 mt-3" onSubmit={sendComment}>
                                            <div className="row g-2 child-cols-12 lg:child-cols-6">
                                                <div className="form-group">
                                                    <input type="text" name="user" className="form-control dark:bg-gray-100 dark:bg-opacity-5 dark:text-white dark:border-gray-800" placeholder="Username" onChange={handleChange} value={comment.user} required />
                                                </div>
                                                <div className="form-group">
                                                    <input type="email" name="email" className="form-control dark:bg-gray-100 dark:bg-opacity-5 dark:text-white dark:border-gray-800" placeholder="Email address" onChange={handleChange} value={comment.email}  />
                                                </div>
                                                <div className="form-group col-12">
                                                    <textarea name="comment" className="form-control dark:bg-gray-100 dark:bg-opacity-5 dark:text-white dark:border-gray-800" placeholder="Comment..." onChange={handleChange} value={comment.comment}  required ></textarea>
                                                </div>
                                                <div className="form-group col-12">
                                                    <button type="submit" className="btn btn-md xl:btn-lg btn-primary col-12">Drop Comment</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </article>
                        </div>
                        <div className="md:col-3">
                            <div className="sidebar-wrap panel vstack gap-2 pb-2 py-4 lg:py-6 xl:py-9" data-uc-sticky="end: .content-wrap; offset: 100; media: @m;">
                                <div className="widget ad-widget vstack gap-2 text-center p-2 bg-white dark:bg-gray-900 rounded">
                                    <div className="widgt-content">
                                        <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5091603862254529"
                                            crossorigin="anonymous"></script>
                                        <ins className="adsbygoogle"
                                            style={{display:"block"}}
                                            data-ad-client="ca-pub-5091603862254529"
                                            data-ad-slot="8159476486"
                                            data-ad-format="auto"
                                            data-full-width-responsive="true"></ins>
                                        <script>
                                            (adsbygoogle = window.adsbygoogle || []).push({});
                                        </script>
                                    </div>
                                </div> 
                                <div className="widget popular-widget vstack gap-2 p-2 bg-white dark:bg-gray-900 rounded">
                                
                                            {/* <div className="widget-title text-center">
                                                <h5 className="fs-7 text-uppercase m-0">Popular now</h5>
                                            </div>
                                            <div className="widget-content">
                                                <div className="row child-cols-12 gx-4 gy-3 sep-x" data-uc-grid>
                                                    <div>
                                                        <article className="post type-post panel uc-transition-toggle">
                                                            <div className="row child-cols g-2" data-uc-grid>
                                                                <div>
                                                                    <div className="hstack items-start gap-3">
                                                                        <span className="h3 lg:h2 lh-1 fst-italic text-center text-primary m-0 min-w-24px">1</span>
                                                                        <div className="post-header panel vstack justify-between gap-1">
                                                                            <h3 className="post-title h6 m-0">
                                                                                <a className="text-none hover:text-primary duration-150" href="blog-details.html">Virtual Reality and Mental Health: Exploring the Therapeutic</a>
                                                                            </h3>
                                                                            <div className="post-meta panel hstack justify-between fs-7 fw-medium text-uppercase text-gray-900 dark:text-white text-opacity-60 d-none md:d-flex">
                                                                                <div className="meta">
                                                                                    <div className="hstack gap-2">
                                                                                        <div>
                                                                                            <div className="post-date hstack gap-narrow">
                                                                                                <span>2mo ago</span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div>
                                                                                            <a href="#post_comment" className="post-comments text-none hstack gap-narrow">
                                                                                                <i className="icon-narrow unicon-chat"></i>
                                                                                                <span>290</span>
                                                                                            </a>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="actions">
                                                                                    <div className="hstack gap-1"></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </article>
                                                    </div>
                                                    <div>
                                                        <article className="post type-post panel uc-transition-toggle">
                                                            <div className="row child-cols g-2" data-uc-grid>
                                                                <div>
                                                                    <div className="hstack items-start gap-3">
                                                                        <span className="h3 lg:h2 lh-1 fst-italic text-center text-primary m-0 min-w-24px">2</span>
                                                                        <div className="post-header panel vstack justify-between gap-1">
                                                                            <h3 className="post-title h6 m-0">
                                                                                <a className="text-none hover:text-primary duration-150" href="blog-details.html">The Future of Sustainable Living: Driving Eco-Friendly Lifestyles</a>
                                                                            </h3>
                                                                            <div className="post-meta panel hstack justify-between fs-7 fw-medium text-uppercase text-gray-900 dark:text-white text-opacity-60 d-none md:d-flex">
                                                                                <div className="meta">
                                                                                    <div className="hstack gap-2">
                                                                                        <div>
                                                                                            <div className="post-date hstack gap-narrow">
                                                                                                <span>2mo ago</span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div>
                                                                                            <a href="#post_comment" className="post-comments text-none hstack gap-narrow">
                                                                                                <i className="icon-narrow unicon-chat"></i>
                                                                                                <span>1</span>
                                                                                            </a>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="actions">
                                                                                    <div className="hstack gap-1"></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </article>
                                                    </div>
                                                    <div>
                                                        <article className="post type-post panel uc-transition-toggle">
                                                            <div className="row child-cols g-2" data-uc-grid>
                                                                <div>
                                                                    <div className="hstack items-start gap-3">
                                                                        <span className="h3 lg:h2 lh-1 fst-italic text-center text-primary m-0 min-w-24px">3</span>
                                                                        <div className="post-header panel vstack justify-between gap-1">
                                                                            <h3 className="post-title h6 m-0">
                                                                                <a className="text-none hover:text-primary duration-150" href="blog-details.html">Smart Homes, Smarter Living: Exploring IoT and AI</a>
                                                                            </h3>
                                                                            <div className="post-meta panel hstack justify-between fs-7 fw-medium text-uppercase text-gray-900 dark:text-white text-opacity-60 d-none md:d-flex">
                                                                                <div className="meta">
                                                                                    <div className="hstack gap-2">
                                                                                        <div>
                                                                                            <div className="post-date hstack gap-narrow">
                                                                                                <span>23d ago</span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div>
                                                                                            <a href="#post_comment" className="post-comments text-none hstack gap-narrow">
                                                                                                <i className="icon-narrow unicon-chat"></i>
                                                                                                <span>15</span>
                                                                                            </a>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="actions">
                                                                                    <div className="hstack gap-1"></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </article>
                                                    </div>
                                                    <div>
                                                        <article className="post type-post panel uc-transition-toggle">
                                                            <div className="row child-cols g-2" data-uc-grid>
                                                                <div>
                                                                    <div className="hstack items-start gap-3">
                                                                        <span className="h3 lg:h2 lh-1 fst-italic text-center text-primary m-0 min-w-24px">4</span>
                                                                        <div className="post-header panel vstack justify-between gap-1">
                                                                            <h3 className="post-title h6 m-0">
                                                                                <a className="text-none hover:text-primary duration-150" href="blog-details.html">How Businesses Are Adapting to E-Commerce and AI Integration</a>
                                                                            </h3>
                                                                            <div className="post-meta panel hstack justify-between fs-7 fw-medium text-uppercase text-gray-900 dark:text-white text-opacity-60 d-none md:d-flex">
                                                                                <div className="meta">
                                                                                    <div className="hstack gap-2">
                                                                                        <div>
                                                                                            <div className="post-date hstack gap-narrow">
                                                                                                <span>29d ago</span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div>
                                                                                            <a href="#post_comment" className="post-comments text-none hstack gap-narrow">
                                                                                                <i className="icon-narrow unicon-chat"></i>
                                                                                                <span>20</span>
                                                                                            </a>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="actions">
                                                                                    <div className="hstack gap-1"></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </article>
                                                    </div>
                                                </div>
                                            </div> */}
                                            <div id="wg-api-football-standings"
                                                data-host="v3.football.api-sports.io"
                                                data-key="779aab70fd9a19cc65b293f01c03b9c6"
                                                data-league=""
                                                data-team=""
                                                data-season=""
                                                data-theme=""
                                                data-show-errors="false"
                                                data-show-logos="true"
                                                className="wg_loader">
                                            </div>
                                        </div>
                                {/* <div className="widget popular-widget vstack gap-2 p-2 bg-white dark:bg-gray-900 rounded">
                                    <div className="widget-title text-center">
                                        <h5 className="fs-7 text-uppercase m-0">Popular now</h5>
                                    </div>
                                    <div className="widget-content">
                                        <div className="row child-cols-12 gx-4 gy-3 sep-x" data-uc-grid>
                                            <div>
                                                <article className="post type-post panel uc-transition-toggle">
                                                    <div className="row child-cols g-2" data-uc-grid>
                                                        <div>
                                                            <div className="hstack items-start gap-3">
                                                                <span className="h3 lg:h2 lh-1 fst-italic text-center text-primary m-0 min-w-24px">1</span>
                                                                <div className="post-header panel vstack justify-between gap-1">
                                                                    <h3 className="post-title h6 m-0">
                                                                        <a className="text-none hover:text-primary duration-150" href="blog-details.html">Virtual Reality and Mental Health: Exploring the Therapeutic</a>
                                                                    </h3>
                                                                    <div className="post-meta panel hstack justify-between fs-7 fw-medium text-uppercase text-gray-900 dark:text-white text-opacity-60 d-none md:d-flex">
                                                                        <div className="meta">
                                                                            <div className="hstack gap-2">
                                                                                <div>
                                                                                    <div className="post-date hstack gap-narrow">
                                                                                        <span>2mo ago</span>
                                                                                    </div>
                                                                                </div>
                                                                                <div>
                                                                                    <a href="#post_comment" className="post-comments text-none hstack gap-narrow">
                                                                                        <i className="icon-narrow unicon-chat"></i>
                                                                                        <span>290</span>
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="actions">
                                                                            <div className="hstack gap-1"></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </article>
                                            </div>
                                                    <div>
                                                        <article className="post type-post panel uc-transition-toggle">
                                                            <div className="row child-cols g-2" data-uc-grid>
                                                                <div>
                                                                    <div className="hstack items-start gap-3">
                                                                        <span className="h3 lg:h2 lh-1 fst-italic text-center text-primary m-0 min-w-24px">2</span>
                                                                        <div className="post-header panel vstack justify-between gap-1">
                                                                            <h3 className="post-title h6 m-0">
                                                                                <a className="text-none hover:text-primary duration-150" href="blog-details.html">The Future of Sustainable Living: Driving Eco-Friendly Lifestyles</a>
                                                                            </h3>
                                                                            <div className="post-meta panel hstack justify-between fs-7 fw-medium text-uppercase text-gray-900 dark:text-white text-opacity-60 d-none md:d-flex">
                                                                                <div className="meta">
                                                                                    <div className="hstack gap-2">
                                                                                        <div>
                                                                                            <div className="post-date hstack gap-narrow">
                                                                                                <span>2mo ago</span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div>
                                                                                            <a href="#post_comment" className="post-comments text-none hstack gap-narrow">
                                                                                                <i className="icon-narrow unicon-chat"></i>
                                                                                                <span>1</span>
                                                                                            </a>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="actions">
                                                                                    <div className="hstack gap-1"></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </article>
                                                    </div>
                                                    <div>
                                                        <article className="post type-post panel uc-transition-toggle">
                                                            <div className="row child-cols g-2" data-uc-grid>
                                                                <div>
                                                                    <div className="hstack items-start gap-3">
                                                                        <span className="h3 lg:h2 lh-1 fst-italic text-center text-primary m-0 min-w-24px">3</span>
                                                                        <div className="post-header panel vstack justify-between gap-1">
                                                                            <h3 className="post-title h6 m-0">
                                                                                <a className="text-none hover:text-primary duration-150" href="blog-details.html">Smart Homes, Smarter Living: Exploring IoT and AI</a>
                                                                            </h3>
                                                                            <div className="post-meta panel hstack justify-between fs-7 fw-medium text-uppercase text-gray-900 dark:text-white text-opacity-60 d-none md:d-flex">
                                                                                <div className="meta">
                                                                                    <div className="hstack gap-2">
                                                                                        <div>
                                                                                            <div className="post-date hstack gap-narrow">
                                                                                                <span>23d ago</span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div>
                                                                                            <a href="#post_comment" className="post-comments text-none hstack gap-narrow">
                                                                                                <i className="icon-narrow unicon-chat"></i>
                                                                                                <span>15</span>
                                                                                            </a>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="actions">
                                                                                    <div className="hstack gap-1"></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </article>
                                                    </div>
                                                    <div>
                                                        <article className="post type-post panel uc-transition-toggle">
                                                            <div className="row child-cols g-2" data-uc-grid>
                                                                <div>
                                                                    <div className="hstack items-start gap-3">
                                                                        <span className="h3 lg:h2 lh-1 fst-italic text-center text-primary m-0 min-w-24px">4</span>
                                                                        <div className="post-header panel vstack justify-between gap-1">
                                                                            <h3 className="post-title h6 m-0">
                                                                                <a className="text-none hover:text-primary duration-150" href="blog-details.html">How Businesses Are Adapting to E-Commerce and AI Integration</a>
                                                                            </h3>
                                                                            <div className="post-meta panel hstack justify-between fs-7 fw-medium text-uppercase text-gray-900 dark:text-white text-opacity-60 d-none md:d-flex">
                                                                                <div className="meta">
                                                                                    <div className="hstack gap-2">
                                                                                        <div>
                                                                                            <div className="post-date hstack gap-narrow">
                                                                                                <span>29d ago</span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div>
                                                                                            <a href="#post_comment" className="post-comments text-none hstack gap-narrow">
                                                                                                <i className="icon-narrow unicon-chat"></i>
                                                                                                <span>20</span>
                                                                                            </a>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="actions">
                                                                                    <div className="hstack gap-1"></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </article>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                        <div className="widget ad-widget vstack gap-2 text-center p-2 bg-white dark:bg-gray-900 rounded">
                                    {/* <div className="widgt-content">
                                        <a className="cstack max-w-300px mx-auto text-none" href="https://themeforest.net/user/reacthemes/portfolio" target="_blank" rel="nofollow">
                                            <img className="d-block dark:d-none rounded" src="../assets/images/common/ad-slot-aside.jpg" alt="Ad slot" />
                                            <img className="d-none dark:d-block rounded" src="../assets/images/common/ad-slot-aside-2.jpg" alt="Ad slot" />
                                        </a>
                                    </div> */}
                                </div> 
                                        <div className="widget social-widget vstack gap-2 text-center p-2 bg-white dark:bg-gray-900 rounded">
                                            <div className="widgt-title">
                                                <h4 className="fs-7 text-uppercase m-0">Follow @playaax</h4>
                                            </div>
                                            <div className="widgt-content">
                                                <Subscribe />
                                                <ul className="nav-x justify-center gap-1 mt-3">
                                                    <li>
                                                        <a href="#fb" className="cstack w-40px h-40px border rounded-circle hover:text-black dark:hover:text-white hover:scale-110 transition-all duration-150"><i className="icon icon-2 unicon-logo-facebook"></i></a>
                                                    </li>
                                                    <li>
                                                        <a href="#x" className="cstack w-40px h-40px border rounded-circle hover:text-black dark:hover:text-white hover:scale-110 transition-all duration-150"><i className="icon icon-2 unicon-logo-x-filled"></i></a>
                                                    </li>
                                                    <li>
                                                        <a href="#in" className="cstack w-40px h-40px border rounded-circle hover:text-black dark:hover:text-white hover:scale-110 transition-all duration-150"><i className="icon icon-2 unicon-logo-instagram"></i></a>
                                                    </li>
                                                    <li>
                                                        <a href="#yt" className="cstack w-40px h-40px border rounded-circle hover:text-black dark:hover:text-white hover:scale-110 transition-all duration-150"><i className="icon icon-2 unicon-logo-youtube"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            

    </>
  )
}

export default Content
