import { createSlice, createAsyncThunk  } from '@reduxjs/toolkit'
import { Subscribe } from '../interfaces/subscriber.interface'
import { addSubscriber } from '../app/axios'

export interface InitialState {
    isLoading:boolean,
    subscriber:Subscribe,
    msg:string,
    error:string
}

const initialState:InitialState = {
    isLoading:false,
    subscriber:{email:''},
    msg:'',
    error:''
}

export const addNewSubscriber = createAsyncThunk('subscribe/addNewSubscriber', async (data:Subscribe) => {
    const response = await addSubscriber(data)
    return response.data
})

const subscribeSlice = createSlice({
    name:"subscribers",
    initialState,
    reducers:{},
    extraReducers: (builder) => {
        builder
            .addCase(addNewSubscriber.pending, (state) => {
                state.isLoading = true
            })
            .addCase(addNewSubscriber.fulfilled, (state, action) => {
                state.isLoading = false
                if (!action.payload.error)
                    state.msg = 'Thanks for subscribing'
            })
            .addCase(addNewSubscriber.rejected, (state) => {
                state.isLoading = false
                state.msg = 'Sorry, could not add user at this time'
            })
    },
})

export default subscribeSlice.reducer