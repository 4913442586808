import React from 'react'
import { useAppSelector } from '../../../hooks/dispatch.hook'
import { Link } from 'react-router-dom'
import { agoTime } from '../../../utility'
import Subscribe from '../../../components/subscribe'

function MoreNews() {
    const {contents} = useAppSelector(state => state.contents)
    const data = contents.filter((item) => item.main === false || item.editorial === false || item.featured === false)
    .slice(0,6)
    // !main, !featured, !editorial
  return (
        <>
            <div id="latest_news" className="latest-news section panel">
                <div className="section-outer panel py-4 lg:py-8">
                    <div className="container max-w-xl">
                        <div className="section-inner">
                            <div className="content-wrap row child-cols-12 g-4" data-uc-grid>
                                <div className="md:col-9">
                                    <div className="main-wrap panel vstack gap-3 lg:gap-6">
                                        <div className="block-layout grid-layout vstack gap-2 panel overflow-hidden">
                                            <div className="block-header panel">
                                                <h2 className="h5 xl:h4 m-0 text-black dark:text-white">More Sports News</h2>
                                            </div>
                                            <div className="block-content">
                                                <div className="row child-cols-12 g-1">
                                                    {/*  */}
                                                    {data.map((item, index) => (
                                                        <div key={index}>
                                                        <article className="post type-post panel uc-transition-toggle bg-white dark:bg-gray-900 p-2 rounded">
                                                            <div className="row child-cols col-match g-2" data-uc-grid>
                                                                <div className="col-12 lg:col-auto">
                                                                    <div className="post-media panel overflow-hidden max-w-350px min-w-100 lg:min-w-350px rounded-1">
                                                                        <div className="featured-image bg-gray-25 dark:bg-gray-800 ratio ratio-16x9">
                                                                            <img className="media-cover image uc-transition-scale-up uc-transition-opaque" src="../assets/images/common/img-fallback.png" data-src={item.image} alt={item.slug} data-uc-img="loading: lazy" />
                                                                        </div>
                                                                        <Link to={"/post/"+item.slug} className="position-cover"></Link>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <div className="vstack justify-between gap-2">
                                                                        <div className="post-header panel vstack gap-1">
                                                                            <div className="post-date hstack gap-narrow fs-7 fw-medium text-uppercase text-gray-900 dark:text-white text-opacity-60 d-none md:d-flex">
                                                                                <span>{agoTime(item.createdAt)}</span>
                                                                            </div>
                                                                            <h3 className="post-title h5 lg:h4 m-0 text-truncate-2">
                                                                                <Link className="text-none hover:text-primary duration-150" to={"/post/"+item.slug}>{item.title}</Link>
                                                                            </h3>
                                                                            <p className="post-excrept ft-tertiary fs-6 text-truncate-2 pe-2" dangerouslySetInnerHTML={{__html:item.content}}></p>
                                                                        </div>
                                                                        <div className="post-meta panel hstack justify-between fs-7 fw-medium">
                                                                            <div className="meta">
                                                                                <div className="hstack gap-2">
                                                                                    <div>
                                                                                        <div className="post-author hstack gap-1">
                                                                                            {/* <Link to="#" data-uc-tooltip={item.author}><img src="../assets/images/avatars/01.png" alt={item.author} className="w-24px h-24px rounded-circle" /></Link> */}
                                                                                            <Link to="#" className="text-black dark:text-white text-none fw-bold">{item.author}</Link>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div>
                                                                                        <Link to="#" className="post-comments text-none hstack gap-narrow">
                                                                                            <i className="icon-narrow unicon-chat"></i>
                                                                                            <span>2</span>
                                                                                        </Link>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="actions">
                                                                                <div className="hstack gap-1"></div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </article>
                                                    </div>
                                                    ))}
                                                    {/*  */}
                                                </div>
                                            </div>
                                            <div className="block-footer cstack lg:mt-2">
                                                <Link to="/posts/latest" className="animate-btn gap-0 btn btn-sm btn-alt-primary bg-transparent text-black dark:text-white border w-100">
                                                    <span>Load more posts</span>
                                                    <i className="icon icon-1 unicon-chevron-right"></i>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="md:col-3">
                                    <div className="sidebar-wrap panel vstack gap-2 pb-2" data-uc-sticky="end: .content-wrap; offset: 100; media: @m;">
                                        {/* <div className="widget ad-widget vstack gap-2 text-center p-2 bg-white dark:bg-gray-900 rounded">
                                            <div className="widgt-content">
                                                <a className="cstack max-w-300px mx-auto text-none" href="https://themeforest.net/user/reacthemes/portfolio" target="_blank" rel="nofollow">
                                                    <img className="d-block dark:d-none rounded" src="../assets/images/common/ad-slot-aside.jpg" alt="Ad slot" />
                                                    <img className="d-none dark:d-block rounded" src="../assets/images/common/ad-slot-aside-2.jpg" alt="Ad slot" />
                                                </a>
                                            </div>
                                        </div> */}
                                        <div className="widget popular-widget vstack gap-2 p-2 bg-white dark:bg-gray-900 rounded">
                                            {/* <div className="widget-title text-center">
                                                <h5 className="fs-7 text-uppercase m-0">Popular now</h5>
                                            </div>
                                            <div className="widget-content">
                                                <div className="row child-cols-12 gx-4 gy-3 sep-x" data-uc-grid>
                                                    <div>
                                                        <article className="post type-post panel uc-transition-toggle">
                                                            <div className="row child-cols g-2" data-uc-grid>
                                                                <div>
                                                                    <div className="hstack items-start gap-3">
                                                                        <span className="h3 lg:h2 lh-1 fst-italic text-center text-primary m-0 min-w-24px">1</span>
                                                                        <div className="post-header panel vstack justify-between gap-1">
                                                                            <h3 className="post-title h6 m-0">
                                                                                <a className="text-none hover:text-primary duration-150" href="blog-details.html">Virtual Reality and Mental Health: Exploring the Therapeutic</a>
                                                                            </h3>
                                                                            <div className="post-meta panel hstack justify-between fs-7 fw-medium text-uppercase text-gray-900 dark:text-white text-opacity-60 d-none md:d-flex">
                                                                                <div className="meta">
                                                                                    <div className="hstack gap-2">
                                                                                        <div>
                                                                                            <div className="post-date hstack gap-narrow">
                                                                                                <span>2mo ago</span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div>
                                                                                            <a href="#post_comment" className="post-comments text-none hstack gap-narrow">
                                                                                                <i className="icon-narrow unicon-chat"></i>
                                                                                                <span>290</span>
                                                                                            </a>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="actions">
                                                                                    <div className="hstack gap-1"></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </article>
                                                    </div>
                                                    <div>
                                                        <article className="post type-post panel uc-transition-toggle">
                                                            <div className="row child-cols g-2" data-uc-grid>
                                                                <div>
                                                                    <div className="hstack items-start gap-3">
                                                                        <span className="h3 lg:h2 lh-1 fst-italic text-center text-primary m-0 min-w-24px">2</span>
                                                                        <div className="post-header panel vstack justify-between gap-1">
                                                                            <h3 className="post-title h6 m-0">
                                                                                <a className="text-none hover:text-primary duration-150" href="blog-details.html">The Future of Sustainable Living: Driving Eco-Friendly Lifestyles</a>
                                                                            </h3>
                                                                            <div className="post-meta panel hstack justify-between fs-7 fw-medium text-uppercase text-gray-900 dark:text-white text-opacity-60 d-none md:d-flex">
                                                                                <div className="meta">
                                                                                    <div className="hstack gap-2">
                                                                                        <div>
                                                                                            <div className="post-date hstack gap-narrow">
                                                                                                <span>2mo ago</span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div>
                                                                                            <a href="#post_comment" className="post-comments text-none hstack gap-narrow">
                                                                                                <i className="icon-narrow unicon-chat"></i>
                                                                                                <span>1</span>
                                                                                            </a>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="actions">
                                                                                    <div className="hstack gap-1"></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </article>
                                                    </div>
                                                    <div>
                                                        <article className="post type-post panel uc-transition-toggle">
                                                            <div className="row child-cols g-2" data-uc-grid>
                                                                <div>
                                                                    <div className="hstack items-start gap-3">
                                                                        <span className="h3 lg:h2 lh-1 fst-italic text-center text-primary m-0 min-w-24px">3</span>
                                                                        <div className="post-header panel vstack justify-between gap-1">
                                                                            <h3 className="post-title h6 m-0">
                                                                                <a className="text-none hover:text-primary duration-150" href="blog-details.html">Smart Homes, Smarter Living: Exploring IoT and AI</a>
                                                                            </h3>
                                                                            <div className="post-meta panel hstack justify-between fs-7 fw-medium text-uppercase text-gray-900 dark:text-white text-opacity-60 d-none md:d-flex">
                                                                                <div className="meta">
                                                                                    <div className="hstack gap-2">
                                                                                        <div>
                                                                                            <div className="post-date hstack gap-narrow">
                                                                                                <span>23d ago</span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div>
                                                                                            <a href="#post_comment" className="post-comments text-none hstack gap-narrow">
                                                                                                <i className="icon-narrow unicon-chat"></i>
                                                                                                <span>15</span>
                                                                                            </a>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="actions">
                                                                                    <div className="hstack gap-1"></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </article>
                                                    </div>
                                                    <div>
                                                        <article className="post type-post panel uc-transition-toggle">
                                                            <div className="row child-cols g-2" data-uc-grid>
                                                                <div>
                                                                    <div className="hstack items-start gap-3">
                                                                        <span className="h3 lg:h2 lh-1 fst-italic text-center text-primary m-0 min-w-24px">4</span>
                                                                        <div className="post-header panel vstack justify-between gap-1">
                                                                            <h3 className="post-title h6 m-0">
                                                                                <a className="text-none hover:text-primary duration-150" href="blog-details.html">How Businesses Are Adapting to E-Commerce and AI Integration</a>
                                                                            </h3>
                                                                            <div className="post-meta panel hstack justify-between fs-7 fw-medium text-uppercase text-gray-900 dark:text-white text-opacity-60 d-none md:d-flex">
                                                                                <div className="meta">
                                                                                    <div className="hstack gap-2">
                                                                                        <div>
                                                                                            <div className="post-date hstack gap-narrow">
                                                                                                <span>29d ago</span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div>
                                                                                            <a href="#post_comment" className="post-comments text-none hstack gap-narrow">
                                                                                                <i className="icon-narrow unicon-chat"></i>
                                                                                                <span>20</span>
                                                                                            </a>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="actions">
                                                                                    <div className="hstack gap-1"></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </article>
                                                    </div>
                                                </div>
                                            </div> */}
                                            <div id="wg-api-football-standings"
                                                data-host="v3.football.api-sports.io"
                                                data-key="779aab70fd9a19cc65b293f01c03b9c6"
                                                data-league=""
                                                data-team=""
                                                data-season=""
                                                data-theme=""
                                                data-show-errors="false"
                                                data-show-logos="true"
                                                className="wg_loader">
                                            </div>
                                        </div>
                                        <div className="widget social-widget vstack gap-2 text-center p-2 bg-white dark:bg-gray-900 rounded">
                                            <div className="widgt-title">
                                                <h4 className="fs-7 text-uppercase m-0">Follow @playaax</h4>
                                            </div>
                                            <div className="widgt-content">
                                                <Subscribe />
                                                <ul className="nav-x justify-center gap-1 mt-3">
                                                    <li>
                                                        <a href="#fb" className="cstack w-40px h-40px border rounded-circle hover:text-black dark:hover:text-white hover:scale-110 transition-all duration-150"><i className="icon icon-2 unicon-logo-facebook"></i></a>
                                                    </li>
                                                    <li>
                                                        <a href="#x" className="cstack w-40px h-40px border rounded-circle hover:text-black dark:hover:text-white hover:scale-110 transition-all duration-150"><i className="icon icon-2 unicon-logo-x-filled"></i></a>
                                                    </li>
                                                    <li>
                                                        <a href="#in" className="cstack w-40px h-40px border rounded-circle hover:text-black dark:hover:text-white hover:scale-110 transition-all duration-150"><i className="icon icon-2 unicon-logo-instagram"></i></a>
                                                    </li>
                                                    <li>
                                                        <a href="#yt" className="cstack w-40px h-40px border rounded-circle hover:text-black dark:hover:text-white hover:scale-110 transition-all duration-150"><i className="icon icon-2 unicon-logo-youtube"></i></a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
  )
}

export default MoreNews
