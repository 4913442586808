import React from 'react'
import { Link } from 'react-router-dom'

interface mProp {
  isOpen:boolean
}

const MobileMenu: React.FC<mProp> = ({isOpen}) => {
  if (!isOpen) 
    return null
  else
  return (
    <div id="uc-menu-panel" data-uc-offcanvas="overlay: true;" className="uc-offcanvas uc-offcanvas-overlay uc-open" style={{display:"block"}}>
      <div className="uc-offcanvas-bar bg-white text-dark dark:bg-gray-900 dark:text-white">
        <header className="uc-offcanvas-header hstack justify-between items-center pb-4 bg-white dark:bg-gray-900">
            <div className="uc-logo">
                <Link to="/" className="h5 text-none text-gray-900 dark:text-white">
                    <img className="w-32px" src="../assets/images/common/logo.png" alt="logo" />
                </Link>
            </div>
            <button className="uc-offcanvas-close p-0 icon-3 btn border-0 dark:text-white dark:text-opacity-50 hover:text-primary hover:rotate-90 duration-150 transition-all" type="button">
                <i className="unicon-close"></i>
            </button>
        </header>
        <div className="panel">
            {/* <form id="search-panel" className="form-icon-group vstack gap-1 mb-3" data-uc-sticky="">
                <input type="email" className="form-control form-control-md fs-6" placeholder="Search.." />
                <span className="form-icon text-gray">
                    <i className="unicon-search icon-1"></i>
                </span>
            </form> */}
            <ul className="nav-y gap-narrow fw-bold fs-5" data-uc-nav>
                <li><Link to="/posts/latest">Latest</Link></li>
                <li><Link to="/category/football">Football</Link></li>
                <li><Link to="/category/basketball">Basketball</Link></li>
                <li><Link to="/category/tennis">Tennis</Link></li>
                <li><Link to="/category/more-sports">More Sports</Link></li>
                <li><Link to="/livescores">Livescores</Link></li>
            </ul>
        </div>
      </div>
    </div>
  )
}

export default MobileMenu
